import React from 'react'
import jwt_decode from 'jwt-decode'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import {
  orderActivated,
} from '../../../../../api/orders/ordersAPI'


export default function OrderActivated() {
  let isAuthenticated = false
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
  const ct = queryParams.get('ct');
  const oid = queryParams.get('oid');
  if (decodeToken) {
    const decoded = jwt_decode(decodeToken)
    isAuthenticated = decoded?.company_id != '' && decoded?.company_level != ''
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace={true} />
    }
  }
  React.useEffect(() => {
    if (ct && oid) {
      activateOrder();
    } else {
      localStorage.removeItem('OrderActivated')
    }
  }, [ct, oid]);

  const activateOrder = async () => {
    try {
      const response = await orderActivated({ ct, oid });
      const isAlreadyActivatedText = "<h1>Your order is already Activated.</h1>";
      if (typeof response.data === 'string') {
        if (response.data.includes(isAlreadyActivatedText)) {
          localStorage.setItem('isAlreadyActivated', true);
        } else {
          localStorage.setItem('isAlreadyActivated', false);
        }
      }
      localStorage.setItem('OrderActivated', true);
      navigate('/', { replace: true });
    } catch (error) {
      localStorage.setItem('OrderActivated', false);
      navigate('/', { replace: true });
    }
  };
}

